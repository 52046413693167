var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-addNewUser","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" Add New User : ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"addUserRules"},[_c('b-form',{staticClass:"m-2",attrs:{"id":"FORMID"},on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-form-group',{attrs:{"id":"name","label":"Name:","label-for":"input-name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-name","placeholder":"Enter Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"E-mail","label-for":"account-e-mail"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"email","placeholder":"Email","type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"account-password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"password","placeholder":"password","type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"confirm-account-password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"password","placeholder":"confirm password","type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.form.conPassword),callback:function ($$v) {_vm.$set(_vm.form, "conPassword", $$v)},expression:"form.conPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"id":"role","label":"Roles:","label-for":"input-role"}},[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.role_options,"name":"radio-inline"},model:{value:(_vm.selected_role),callback:function ($$v) {_vm.selected_role=$$v},expression:"selected_role"}})],1)],1)],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex mb-2 ml-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"form":"FORMID","variant":"success","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_vm._v(" Submit ")]),_c('b-button',{staticClass:"mr-2",attrs:{"type":"reset","variant":"danger","form":"FORMID"}},[_vm._v(" Reset ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-danger","form":"FORMID"},on:{"click":_vm.closeSidebar}},[_vm._v(" Cancel ")])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }