<template>
    <div>
        <!-- <span v-if="checkPermission('all_access')"> -->
        <b-sidebar id="sidebar-addNewUser" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h3 class="mb-0">
                        Add New User :
                    </h3>
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>

                <validation-observer ref="addUserRules">
                    <b-form @submit="onSubmit" @reset="onReset" class="m-2" id="FORMID">

                        <b-form-group id="name" label="Name:" label-for="input-name">
                            <validation-provider #default="{ errors }" name="name" rules="required">
                                <b-form-input id="input-name" v-model="form.name" placeholder="Enter Name">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="E-mail" label-for="account-e-mail">
                            <validation-provider #default="{ errors }" name="Email" rules="required|email">
                                <b-form-input v-model="form.email" name="email" placeholder="Email" type="email" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Password" label-for="account-password">
                            <validation-provider #default="{ errors }" name="Password" vid="Password"
                                rules="required|password">
                                <b-form-input v-model="form.password" name="password" placeholder="password" type="password"
                                    :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Confirm Password" label-for="confirm-account-password">
                            <validation-provider #default="{ errors }" name="Confirm Password"
                                rules="required|confirmed:Password">
                                <b-form-input v-model="form.conPassword" name="password" placeholder="confirm password"
                                    type="password" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                            
                            <b-form-group id="role" label="Roles:" label-for="input-role">
                                <b-form-radio-group v-model="selected_role" :options="role_options" class="demo-inline-spacing" name="radio-inline" />

                        </b-form-group>



                    </b-form>
                </validation-observer>
            </template>
            <!-- <template #cell(role)="row">
                <span>{{ row.form.roles[0].display_name }}</span>
            </template> -->
            <template #footer>
                <div class="flex mb-2 ml-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" form="FORMID" variant="success" class="mr-2"
                        type="submit" @click.prevent="onSubmit">
                        Submit
                    </b-button>

                    <b-button type="reset" variant="danger" class="mr-2" form="FORMID">
                        Reset
                    </b-button>

                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" form="FORMID"
                        @click="closeSidebar">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-sidebar>
        <!-- </span> -->
    </div>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormRadioGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import User from '../../Helpers/User'

export default {
    components: {
        BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BSidebar, BFormRadioGroup,
        ValidationProvider, ValidationObserver, ToastificationContent
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            required, email, confirmed, password,
            form: {
                name: '',
                email: '',
                password: '',
                conPassword: ''
            },
            role_options: [
            ],
            selected_role: ''
        }
    },

    created() {
        this.getAllRoles();
    },
    methods: {
        checkPermission(permission) {

            return User.checkPermission(permission)
        },
        onReset(event) {
            event.preventDefault()
            this.clearForm()
        },
        getAllRoles() {
    this.loader = true;
    axios
        .get(process.env.VUE_APP_API + "/roles")
        .then((res) => {
            console.log(res.data); // Log the response data
            this.loader = false;
            this.role_options = res.data.map((option) => ({
                text: option.name,
                value: option.id,
            }));
        })
        .catch((error) => {
            this.loader = false;
            console.error(error); // Log any errors
        });
},
        clearForm() {
            this.form.name = '',
                this.form.email = '',
                this.form.password = '',
                this.form.conPassword = ''
        },
        onSubmit(event) {
            this.$refs.addUserRules.validate().then(success => {
                if (success) {
                    try {
                        axios({
                            method: 'post',
                            url: process.env.VUE_APP_API + '/register',

                            data: {
                                'name': this.form.name,
                                'email': this.form.email,
                                'password': this.form.password,
                                'role': this.selected_role
                            }
                        })
                            .then(res => {
                                if (res.data.success) {
                                    this.closeSidebar()
                                    this.$emit('refresh')
                                    this.$toast
                                        ({
                                            component: ToastificationContent,
                                            props: {
                                                title: 'New User Added',
                                                icon: 'AlertOctagonIcon',
                                                text: '',
                                                variant: 'success',
                                            },
                                        },
                                            {
                                                position: 'top-right'
                                            })
                                }
                                else {
                                    alert('sdsdd')
                                    this.$toast
                                        ({
                                            component: ToastificationContent,
                                            props: {
                                                title: 'Failed',
                                                icon: 'AlertOctagonIcon',
                                                text: res.data.success,
                                                variant: 'danger',
                                            },
                                        },
                                            {
                                                position: 'top-left'
                                            })
                                }
                            })
                    }
                    catch (error) {
                        console.log(error)
                    }
                }

            })
        },
        closeSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-addNewUser')
            this.clearForm()
        },
    },
    computed: {
    }

}
</script>
